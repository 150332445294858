.login-button {
  width: 100%;
}

.login-title {
  font-family: 'OnestBold', serif;
  font-size: 32px;
}

.white-text {
  color: #FFFFFF;
}

.dark-text {
  color: #888EA7;
}

.main-div {
  text-align: right;
  width: 300px;
  margin-top: 50px;
}

@media (min-width:961px) {
  .main-div {
    width: 400px;
  }
}

.login-form {
  text-align: center;
}

.underlined {
  text-decoration: underline;
}

.forget-password {
  text-align: right;
}

.bad-credentials {
  display: flex !important;
  align-items: center;
}
.bad-credentials-text {
  margin: 0 0 0 10px;
}
@primary-color: #75BC20;