html {
  scroll-behavior: smooth;
}

button {
  border-radius: 4px !important;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
}

input {
  border-radius: 4px !important;
}

.ant-select-selector {
    border-radius: 4px !important;
}