@media (min-width:961px) {
  .central-page {
    padding: 20px;
    height: 100%;
  }

  .central-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-div {
    margin: 50px;
    display: inline-block;
  }
}

@media (max-width:961px) {
  .central-page {
    padding: 20px;
  }

  .central-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@primary-color: #75BC20;