.dark-green-text {
  color: #00463B !important;
}

.main-registration-div {
  background-color: #F5F9F0;
  border-radius: 4px;
  margin: 20px;
  padding-top: 10px;
  min-width: 70vw;
  text-align: center;
}

.col-register {
  padding-left: 25px;
  padding-right: 25px;
}

.col-avatar {
  display: flex !important;
  flex-direction: column;
  justify-content: space-around;
}

.avatar-button {
  border: none !important;
  width: 140px !important;
  height: 140px !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
@primary-color: #75BC20;